<template>
  <div class="retrieveAct">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">账号找回</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <div class="cellBox">
        <div class="cellItem" @click.stop>
          <div class="cellTitle">账号凭证</div>
          <svg-icon class="cellIcon" iconClass="settingArrow" />
          <input class="upload" type="file" @change="uploadQrcode($event)" />
        </div>

        <router-link tag="div" to="/phoneRetrieval" class="cellItem">
          <div class="cellTitle">手机号码</div>
          <svg-icon class="cellIcon" iconClass="settingArrow" />
        </router-link>

        <router-link tag="div" to="/customerService" class="cellItem">
          <div class="cellTitle">联系客服</div>
          <svg-icon class="cellIcon" iconClass="settingArrow" />
        </router-link>
      </div>
    </div>
    <FullScreenLoading :isLoadingShow="isLoadingShow" />
  </div>
</template>

<script>
import { loginGuest } from "@/api/user";
import { Toast } from "vant";
import { setSessionItem, setLocalItem } from "@/utils/longStorage";
import qrcode from "@/assets/js/llqrcode";
import FullScreenLoading from "@/components/FullScreenLoading";
export default {
  data() {
    return {
      isLoadingShow: false,
    };
  },
  components: {
    FullScreenLoading,
  },
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    // 上传解析二维码
    async uploadQrcode(e) {
      this.isLoadingShow = true;
      try {
        let base64 = await this.compress(e.target.files[0]);
        // eslint-disable-next-line no-undef
        qrcode.decode(base64);
        // eslint-disable-next-line no-undef
        qrcode.callback = (imgMsg) => {
          this.login(imgMsg);
        };
      } catch (e) {
        this.isLoadingShow = false;
        Toast("二维码识别失败");
      }
    },
    // 压缩图片
    compress(file) {
      let selt = this;
      if (!file || !window.FileReader) return;
      if (/^image/.test(file.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise((resolve, reject) => {
          reader.onloadend = function () {
            let result = reader.result;
            let img = new Image();
            img.src = result;
            img.onload = function () {
              let canvas = document.createElement("canvas");
              let ctx = canvas.getContext("2d");
              let width = img.width;
              let height = img.height;
              canvas.width = width;
              canvas.height = height;
              // 铺底色
              ctx.fillStyle = "#fff";
              ctx.fillRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(img, 0, 0, width, height);
              //进行最小压缩
              let ndata = canvas.toDataURL("image/jpeg", 0.5);
              resolve(ndata);
            };
          };
        });
      }
    },
    //登陆
    async login(qrurl) {
      try {
        let ret = await this.$Api(loginGuest, {
          content: qrurl,
        });
        this.isLoadingShow = false;
        if (ret && ret.code === 200) {
          Toast("登录成功");
          setSessionItem("userInfo", JSON.stringify(ret.data));
          setLocalItem("token", ret.data.token);
          this.$store.dispatch("commit/SET_USERINFO", ret.data);
          this.$router.go(-2);
        } else {
          Toast(ret.tip || "登录失败");
        }
      } catch (error) {
        this.isLoadingShow = false;
        Toast("登录失败");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.retrieveAct {
  height: 100%;
  background: #f5f5f5;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      // flex: 2;
      // text-align: center;
      font-size: 16px;
      color: #000;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }

  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    padding: 12px 10px 0;
    box-sizing: border-box;
    .cellBox {
      padding: 0 12px;
      box-sizing: border-box;
      border-radius: 10px;
      background: #fff;
      box-shadow: -5px -5px 20px 0px #fff,
        5px 5px 20px 0px rgba(174, 174, 192, 0.5);
    }

    .cellItem {
      display: flex;
      align-items: center;
      justify-content: space-between;

      height: 44px;
      font-size: 14px;
      border-bottom: 1px solid #e6e6e6;
      .cellTitle {
        color: #333;
        font-weight: 600;
        font-size: 12px;
      }

      .cellIcon {
        width: 12px;
        height: 12px;
      }

      .copyBtn {
        width: 46px;
        height: 19px;
        background: url("../../../assets/png/copyBtn.png") no-repeat;
        background-size: 100% 100%;
      }

      .upload {
        position: absolute;
        left: 0;
        width: 100%;
        height: 49px;
        opacity: 0;
      }
    }
    .cellItem:last-child {
      border: none;
    }
  }
}
</style>
